import React from "react";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { makeStyles } from "@material-ui/core/styles";
function FolderView({ invoiceList, parentDisplayCallback }) {
  const useStyles = makeStyles({
    root: {
      height: 216,
      flexGrow: 1,
      maxWidth: 400,
    },
  });
  const classes = useStyles();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  console.log("Folder View");
  var dates = [
    ...new Set(
      invoiceList
        .sort((a, b) => a.installDate[0] - b.installDate[0])
        .map((item) =>
          item.installDate[0].toDate().toLocaleString("default", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })
        )
    ),
  ];
  var projects = [
    ...new Set(invoiceList.map((item) => item.projectName)),
  ].sort();
  const sortedStoreList = invoiceList.sort((a, b) => a.store - b.store);
  return (
    <div>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        multiSelect
        style={{ overflowY: "scroll", height: "90vh" }}
      >
        {projects.map((project) => (
          <TreeItem nodeId={project} label={project}>
            {months.map((month) => (
              <TreeItem nodeId={month} label={month}>
                {dates
                  .filter((item) => {
                    if (item.includes(month)) {
                      return item;
                    }
                  })
                  .map((date) => (
                    <TreeItem nodeId={date} label={date}>
                      {sortedStoreList
                        ?.filter((invoice) => {
                          if (
                            invoice.projectName === project &&
                            invoice.folderDate.includes(date)
                          ) {
                            return invoice;
                          }
                        })
                        .map((i) => (
                          <TreeItem
                            label={i.store}
                            onClick={() => parentDisplayCallback(i)}
                          ></TreeItem>
                        ))}
                    </TreeItem>
                  ))}
              </TreeItem>
            ))}
          </TreeItem>
        ))}
      </TreeView>
    </div>
  );
}

export default React.memo(FolderView);
