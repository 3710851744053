import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";

export default function DatePicker({ name, label, value, onChange }) {
  const convertDate = (name, value) => ({
    target: {
      name,
      value,
    },
  });
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="normal"
        id="date-picker-dialog"
        format="MM/dd/yyyy"
        name={name}
        label={label}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        value={value}
        onChange={(date) =>
          onChange(
            convertDate(
              name,
              date.toLocaleString("default", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })
            )
          )
        }
      ></KeyboardDatePicker>
    </MuiPickersUtilsProvider>
  );
}
