import React from "react";
import image from "../src/image.png";
import "./Nav.css";
import { Link, NavLink } from "react-router-dom";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { useStateValue } from "./StateProvider";
import { auth } from "./firebase";
import { useHistory } from "react-router-dom";
import { actionTypes } from "./reducer";
function Nav() {
  //const [{ user }, dispatch] = useStateValue();
  const history = useHistory();

  const handleAuthentication = () => {
    auth.signOut();
    history.push("/login");
  };
  return (
    <div className="header">
      <NavLink to="/">
        <img className="header__logo" src={image} alt="Netech Logo" />
      </NavLink>
      {/* onClick={handleAuthentication} */}
      {/* to={!user && "/login"} */}
      <div className="header__nav">
        <NavLink to={"/2021"}>
          <div className="header__option">
            <ReceiptIcon />
            <span className="header__optionLineTwoheader__basketCount">
              2021
            </span>
          </div>
        </NavLink>
        <NavLink to={"/invoiceList"}>
          <div className="header__option">
            <ReceiptIcon />
            <span className="header__optionLineTwoheader__basketCount">
              Invoice List
            </span>
          </div>
        </NavLink>
        {/* <NavLink to={"/employees"}>
          <div className="header__option">
            <ReceiptIcon />
            <span className="header__optionLineTwoheader__basketCount">
              Employees
            </span>
          </div>
        </NavLink> */}
        <NavLink to={"/projects"}>
          <div className="header__option">
            <AssignmentIndIcon />
            <span className="header__optionLineTwoheader__basketCount">
              Projects
            </span>
          </div>
        </NavLink>
        <NavLink to="/">
          <div className="header__option">
            <AddBoxIcon />
            <span className="header__optionLineTwoheader__basketCount">
              Create Invoice
            </span>
          </div>
        </NavLink>
        <NavLink to={"/login"}>
          <div onClick={handleAuthentication} className="header__option">
            <span className="header__optionLineOne">
              {auth.currentUser
                ? "Hello " + auth.currentUser.email
                : "Hello Guest"}
            </span>

            <span className="header__optionLineTwo">
              {auth.currentUser ? "Sign Out" : "Sign In"}
            </span>
          </div>
        </NavLink>
      </div>
    </div>
  );
}

export default Nav;
