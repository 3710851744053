import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

function FilterInvoice({
  invoiceList,
  parentDeleteCallback,
  parentDisplayCallback,
  selectedIndex,
}) {
  console.log("Filter Invoice");
  const [filter, setFilter] = useState("");
  return (
    <div>
      <div>
        <h2>Invoice List</h2>
        <input
          type="text"
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Search Invoice/Store"
          style={{
            alignItems: "center",
            width: "90%",
            height: "3vh",
            fontSize: 14,
          }}
        />
      </div>
      <div style={{ marginTop: 4 }}>
        <div className="sidebar">
          <List>
            {invoiceList
              ?.filter((item) => {
                if (filter == "") {
                  return item;
                } else if (item.invoice.includes(filter)) {
                  return item;
                } else if (item.store.includes(filter)) {
                  return item;
                }
              })
              .map((invoice) => (
                <ListItem
                  button
                  key={invoice.id}
                  selected={selectedIndex === invoice.id}
                  onClick={() => parentDisplayCallback(invoice)}
                  style={{
                    height: "6vh",
                    width: "100%",
                    borderRadius: "10px",
                    marginTop: "10px",
                    alignContent: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <ListItemText>
                    Invoice: {invoice.invoice} Store: {invoice.store}
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <div onClick={() => parentDeleteCallback(invoice.id)}>
                      <DeleteIcon />
                    </div>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </div>
      </div>
    </div>
  );
}

export default React.memo(FilterInvoice);
