import React, { useState, useEffect } from "react";
import AddIcon from "@material-ui/icons/Add";
import { v4 as uuidv4 } from "uuid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { Alert } from "@material-ui/lab";
import {
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { db } from "./firebase";
import "./Project.css";

function Projects() {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const [project, setProject] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedProjectManager, setSelectedProjectManager] = useState("");

  const [projectList, setProjectList] = useState([]);
  const [projectManager, setProjectManager] = useState("");
  const [projectManagerList, setprojectManagerList] = useState([]);
  const [assignedList, setAssignedList] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    db.collection("projects").onSnapshot((snapshot) => {
      setProjectList(snapshot.docs.map((doc) => doc.data()));
    });
    db.collection("managers").onSnapshot((snapshot) => {
      setprojectManagerList(snapshot.docs.map((doc) => doc.data()));
    });
    db.collection("Project-Manager").onSnapshot((snapshot) => {
      setAssignedList(snapshot.docs.map((doc) => doc.data()));
    });
    // invoices2022
    // const filteredList = list.filter(obj => obj.invoiceDate.includes("November"))
    // console.log(filteredList)
    // filteredList.map(item => db.collection("invoices2022").doc(item.id).set(item))
    // db.collection("backupData2022").onSnapshot((snapshot) => {
    //   snapshot.docs.map((doc) => {
    //     if(doc.data().invoiceDate.includes("November")){
    //       db.collection("invoices2022").doc(doc.id).set(doc.data())
    //     }
    //   })
    // })
  }, []);
  const addProject = () => {
    setError("");
    const found = projectList.find((item) => item.name === project);
    if (found) {
      setProject("");
      setError("Project already Added");
      return;
    }
    if (project !== "") {
      var newProject = { id: uuidv4(), name: project };
      // const newList = projectList.concat(newProject);
      // setProjectList(newList);
      db.collection("projects").doc(newProject.id).set({
        id: newProject.id,
        name: newProject.name,
      });

      setProject("");
    }
  };
  const addProjectManager = async () => {
    setError("");
    const found = projectManagerList.find(
      (item) => item.name === projectManager
    );
    if (found) {
      setProjectManager("");
      setError("Manager already Added");
      return;
    }
    if (projectManager !== "") {
      var newManager = {
        id: uuidv4(),
        name: projectManager,
      };

      db.collection("managers").doc(newManager.id).set({
        id: newManager.id,
        name: newManager.name,
      });
      setProjectManager("");
    }
  };
  const handleAssign = () => {
    const found = assignedList.find(
      (item) =>
        item.project === selectedProject &&
        item.manager === selectedProjectManager
    );
    setError("");
    if (found) {
      setError("Project already assigned to this Manager");
      return;
    }
    if (selectedProject !== "" && selectedProjectManager !== "") {
      var newAssign = {
        id: uuidv4(),
        project: selectedProject,
        manager: selectedProjectManager,
      };
      const newList = assignedList.concat(newAssign);
      db.collection("Project-Manager").doc(newAssign.id).set({
        id: newAssign.id,
        project: newAssign.project,
        manager: newAssign.manager,
      });
      setAssignedList(newList);
    }
  };
  function handleRemoveProject(id) {
    let deletedProject = projectList.find((item) => item.id == id);
    let assignedProject = assignedList.find(
      (item) => item.project == deletedProject.name
    );
    if (assignedProject != undefined) {
      db.collection("Project-Manager").doc(assignedProject.id).delete();
    }

    const newList = projectList.filter((item) => item.id !== id);
    db.collection("projects").doc(id).delete();

    if (projectList.filter((item) => item.id === id) == true) {
      setError("found");
    }
    setProjectList(newList);
  }
  function handleRemoveProjectManager(id) {
    setError("");
    let deletedManager = projectManagerList.find((item) => item.id == id);
    assignedList.map((item) => {
      if (item.manager == deletedManager.name) {
        db.collection("Project-Manager")
          .doc(item.id)
          .delete()
          .then(() => setError("assiend projects deleted"));
      }
    });

    const newList = projectManagerList.filter((item) => item.id !== id);
    db.collection("managers")
      .doc(id)
      .delete()
      .then(() => {
        setError("Doc deleted successfully");
      })
      .catch((err) => {
        setError("Error deleting Project Manager ");
      });
    setprojectManagerList(newList);
  }
  return (
    <div class="wrapper">
      <div id="sidebar">
        <div class="sidebar-header" style={{ fontSize: 27 }}>
          Current Projects
        </div>

        <div class="sidebar-filter" style={{ marginTop: "2vh" }}>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {projectManagerList.map((manager) => (
              <TreeItem nodeId={manager.id} label={manager.name}>
                {assignedList
                  .filter((item) => item.manager === manager.name)
                  .map((assign) => (
                    <TreeItem nodeId={assign.id} label={assign.project} />
                  ))}
              </TreeItem>
            ))}
          </TreeView>
        </div>
      </div>

      <div id="content">
        <h3>Assign Project</h3>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "2vw",
          }}
        >
          <div>
            <InputLabel id="projectName">Project</InputLabel>
            <Select
              labelId="projectName"
              variant="outlined"
              label="Project"
              name="projectName"
              value={selectedProject}
              onChange={(e) => {
                setSelectedProject(e.target.value);
              }}
            >
              {projectList.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <Button
            style={{
              width: "10vw",
              backgroundColor: "lightslategray",
              borderRadius: "50px",
              marginTop: "1vw",
            }}
            onClick={handleAssign}
          >
            Assign
          </Button>
          <div>
            <InputLabel id="manager">Manager</InputLabel>
            <Select
              labelId="manager"
              variant="outlined"
              label="Manager"
              name="manager"
              value={selectedProjectManager}
              onChange={(e) => {
                setSelectedProjectManager(e.target.value);
              }}
            >
              {projectManagerList.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>

        <div style={{ marginTop: "2vw" }}>
          <h3>Add Projects</h3>
          <div style={{ flex: 1, flexDirection: "row" }}>
            <TextField
              variant="outlined"
              type="text"
              onChange={(e) => setProject(e.target.value)}
              value={project}
              placeholder="Add Project"
            />
            <Button
              onClick={addProject}
              style={{ height: "55px", backgroundColor: "white" }}
            >
              <AddIcon />
            </Button>
          </div>
        </div>

        <div style={{ marginTop: "2vw" }}>
          <h3>Add Project Manager</h3>
          <TextField
            variant="outlined"
            type="text"
            onChange={(e) => setProjectManager(e.target.value)}
            value={projectManager}
            placeholder="Add Project Manager"
          />
          <Button
            onClick={addProjectManager}
            style={{ height: "55px", backgroundColor: "white" }}
          >
            <AddIcon />
          </Button>
        </div>
        <div>{error ?? <Alert severity="error">{error}</Alert>}</div>
      </div>

      <div id="projects">
        <div>
          <Typography variant="h6">Projects</Typography>
          <div style={{ scrollBehavior: "smooth" }}>
            <List dense={dense}>
              {projectList.map((item) => (
                <ListItem key={item.id}>
                  <ListItemText
                    primary={item.name}
                    secondary={secondary ? "Secondary text" : null}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveProject(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </div>
      <div id="managers">
        <div>
          <Typography variant="h6">Project Managers</Typography>
          <div>
            <List dense={dense}>
              {projectManagerList.map((item) => (
                <ListItem key={item.id}>
                  <ListItemText
                    primary={item.name}
                    secondary={secondary ? "Secondary text" : null}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveProjectManager(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
