import React, { useState } from "react";
import { auth } from "./firebase";
import { useHistory } from "react-router-dom";
import "./login.css";
import image from "../src/image.png";
// import { useStateValue } from "./StateProvider";
// import { actionTypes } from "./reducer";

function Login() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const signIn = (e) => {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(email, password)
      .then((auth) => {
        history.push("/");
      })
      .catch((error) => alert(error.message));
  };

  return (
    <div className="login">
      <img className="login__logo" src={image} />

      <div className="login__container">
        <h1>Sign-in</h1>
        <form>
          <h5>E-mail</h5>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <h5>Password</h5>
          <input
            type={show ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div>
            <input
              type="checkbox"
              defaultChecked={show}
              onChange={(e) => setShow(e.target.checked)}
            />
            <span style={{ fontSize: 12 }}> show password</span>
          </div>

          <button
            type="submit"
            onClick={signIn}
            className="login__signInButton"
          >
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
