import { Button, Grid, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DatePicker from "../components/DatePicker";

const initialSearchCriteria = {
  invoice: "",
  store: "",
  project: "",
  installDate: "",
};

function SearchCriteria({ projectList }) {
  const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria({
      ...searchCriteria,
      [name]: value,
    });
  };
  const onSearch = () => {
    console.log(searchCriteria);
  };
  const onReset = () => {
    setSearchCriteria(initialSearchCriteria);
  };
  return (
    <div>
      <h1>Search Criteria</h1>
      <hr />
      <br />
      <Grid container spacing={4}>
        <Grid item>
          <TextField
            variant="outlined"
            label="Invoice#"
            name="invoice"
            value={searchCriteria.invoice}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            label="Store#"
            name="store"
            value={searchCriteria.store}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Project"
            variant="outlined"
            select="None"
            name="project"
            value={searchCriteria.project}
            onChange={handleInputChange}
          >
            {projectList
              .sort(function (a, b) {
                var nameA = a.project.toUpperCase(); // ignore upper and lowercase
                var nameB = b.project.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              })
              .map((item) => (
                <MenuItem key={item.id} value={item.project}>
                  {item.project}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item>
          <DatePicker
            name="installDate"
            label="Install Date"
            value={searchCriteria.invoiceDate}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onSearch}
          >
            Search
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onReset}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default SearchCriteria;
