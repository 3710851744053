import axios from "axios";

const instance = axios.create({
  baseURL: "https://sheet.best/api/sheets/2713da7b-d5c8-422d-9f9e-f84c96943090" 
  
});

export default instance;

//"http://localhost:5001/billing-tracker-317014/us-central1/api"
//"https://us-central1-checkfunction-d57bd.cloudfunctions.net/api",