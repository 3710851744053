import React, { useState, useEffect } from "react";
import {
  makeStyles,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import DatePicker from "./components/DatePicker";
import SaveIcon from "@material-ui/icons/Save";
import "./Home.css";
import { db } from "./firebase";
//import { Link } from "react-router-dom";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { v4 as uuidv4 } from "uuid";
import axios from "./axios";
//import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../src/components/TabPanel";

var date = new Date();

const initialValues = {
  invoice: 0.0,
  projectName: "",
  projectNumber: "",
  store: "",
  address: "",
  city: "",
  province: "",
  installDate: [date, date],
  folderDate: date,
  invoiceDate: date.toLocaleString("default", {
    month: "long",
    day: "numeric",
    year: "numeric",
  }),
  value: 0.0,
  gstAmount: 0.0,
  gstPercent: 0.0,
  deliverables: 0.0,
  hours: 0.0,
  mileage: 0.0,
  food: 0.0,
  travelTime: 0.0,
  luggage: 0.0,
  parking: 0.0,
  tools: 0.0,
  gas: 0.0,
  ferry: 0.0,
  notes: "",
  netechNotes: "",
};

const useStyle = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      height: "100%",
      margin: theme.spacing(1),
    },
    tab: {
      backgroundColor: theme.palette.background.paper,
      width: 500,
    },
  },
}));

function Home() {
  const [values, setValues] = useState(initialValues);
  const [invoices, setInvoices] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [projectManager, setProjectManager] = useState("");
  const theme = useTheme();
  const classes = useStyle();
  const [value, setValue] = React.useState(0);
  //const [gst, setGst] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    //console.log(values);
    projectList.forEach((item) => {
      if (item.project === values.projectName) {
        setProjectManager(item.manager);
      }
    });
  };

  const handleDetailsInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    projectList.forEach((item) => {
      if (item.project === values.projectName) {
        setProjectManager(item.manager);
      }
    });
  };
  const handleAddDetails = () => {
    setError("");
    setSuccess("");

    if (values.store === "" || values.projectName === "") {
      setError("Project Name and Store# is required");
    } else {
      var id = uuidv4();
      // invoices2022
      db.collection("invoices2022")
        .doc(id)
        .set({
          ...values,
          id,
          folderDate: values.installDate[0].toLocaleString("default", {
            month: "long",
            day: "numeric",
            year: "numeric",
          }),
          invoice: "0",
        });
      setSuccess("Added");
    }
    setValues(initialValues);
  };
  const saveInvoice = () => {
    // e.preventDefault();
    var gstPercent = 0;
    if (
      values.province === "MB" ||
      values.province === "BC" ||
      values.province === "AB" ||
      values.province === "SK" ||
      values.province === "YT" ||
      values.province === "NT" ||
      values.province === "NU"
    ) {
      gstPercent = 5;
    } else if (values.province === "ON") {
      gstPercent = 13;
    } else if (values.province === "QC") {
      gstPercent = 14.975;
    } else if (
      values.province === "NB" ||
      values.province === "PE" ||
      values.province === "NS" ||
      values.province === "NL"
    ) {
      gstPercent = 15;
    } else {
      gstPercent = 0;
    }

    setError("");
    setSuccess("");
    const found = invoices.find((item) => item.invoice === values.invoice);
    if (found) {
      setError("Invoice already added");
      return;
    } else if (
      values.province === "" ||
      values.invoice === 0 ||
      values.invoice === "" ||
      values.invoice === "0"
    ) {
      setError("Province/Invoice is mandatory");
    } else if (values.invoice) {
      var id = uuidv4();
      db.collection("invoices2022")
        .doc(id)
        .set({
          ...values,
          id,
          folderDate: values.installDate[0].toLocaleString("default", {
            month: "long",
            day: "numeric",
            year: "numeric",
          }),
        });
      // db.collection("invoice").doc(values.invoice).set({
      //   invoice: values,
      // });
      if (values.hours === "") {
        values.hours = 0;
      }
      if (values.deliverables === "") {
        values.deliverables = 0;
      }
      if (values.travelTime === "") {
        values.travelTime = 0;
      }
      if (values.mileage === "") {
        values.mileage = 0;
      }
      if (values.food === "") {
        values.food = 0;
      }
      var ratePerHour = Number(43);
      var subtotal = Number(
        parseFloat(values.hours) * ratePerHour +
          parseFloat(values.deliverables) * ratePerHour +
          parseFloat(values.travelTime) * ratePerHour +
          parseFloat(values.mileage) * 0.60 +
          parseFloat(values.food)
      );
      var expenseList = [];
      if (values.luggage > 0) {
        expenseList.push("Luggage");
      }
      if (values.parking > 0) {
        expenseList.push("Parking");
      }
      if (values.tools > 0) {
        expenseList.push("Material");
      }
      if (values.gas > 0) {
        expenseList.push("Gas");
      }
      if (values.ferry > 0) {
        expenseList.push("Ferry");
      }

      var hst = Number((subtotal * parseFloat(gstPercent)) / 100);
      // var luggageFees = parseFloat(values.luggage);
      if (values.luggage === "") {
        values.luggage = 0;
      }
      if (values.parking === "") {
        values.parking = 0;
      }
      if (values.tools === "") {
        values.tools = 0;
      }
      if (values.gas === "") {
        values.gas = 0;
      }
      if (values.ferry === "") {
        values.ferry = 0;
      }
      var expenseAmount = Number(
        parseFloat(values.luggage) +
          parseFloat(values.parking) +
          parseFloat(values.tools) +
          parseFloat(values.gas) +
          parseFloat(values.ferry)
      );
      var expenseName = expenseList.toString();
      var total = subtotal + hst + expenseAmount;
      var currDate = "";
      if (values.installDate[0].getDate() === values.installDate[1].getDate()) {
        currDate = values.installDate[0].toLocaleString("default", {
          month: "long",
          day: "numeric",
          year: "numeric",
        });
      } else {
        currDate =
          values.installDate[0].toLocaleString("default", {
            month: "long",
            day: "numeric",
            year: "numeric",
          }) +
          "-" +
          values.installDate[1].toLocaleString("default", {
            month: "long",
            day: "numeric",
            year: "numeric",
          });
      }
      var object = {
        "Project Manager": projectManager,
        Project: values.projectName,
        "Project Number": values.projectNumber,
        "Store Number": values.store,
        "Store Location Address": values.address,
        City: values.city,
        PROVINCE: values.province,
        "Install Date": currDate,
        Vendor: "Netech",
        "Invoice#": values.invoice,
        "Invoice Date": values.invoiceDate,
        "Labor Hours": values.hours,
        "Labour Rate": ratePerHour,
        "Labour Amount": values.hours * ratePerHour,
        "Deliverable Hours": values.deliverables,
        "Deliverable Rate": ratePerHour,
        "Deliverable Amount": values.deliverables * ratePerHour,
        "Travel Time": values.travelTime,
        "Travel Rate": ratePerHour,
        "Travel Amount": values.travelTime * ratePerHour,
        Mileage: values.mileage,
        "Rate per kms (CAD)": 0.60,
        "Milage Amount": values.mileage * 0.60,
        Food: values.food,
        "Other Charges": expenseName,
        "Luggage/Parking/Tools Amount": expenseAmount,
        Subtotal: subtotal.toFixed(2),
        HST: hst.toFixed(2),
        "Total Amount": total.toFixed(2),
        "Date Submitted": "",
        Note: values.notes,
      };
      axios
        .post("", object)
        .then((response) => {
          setValues(initialValues);
          setError("");
          setSuccess("Invoice Added!!");
        })
        .catch((err) => {
          setError(err.response.data.detail);
        });
    } else {
      setError("Invoice is mandatory");
    }
  };

  useEffect(() => {
    db.collection("invoices2022").onSnapshot((snapshot) => {
      setInvoices(snapshot.docs.map((doc) => doc.data()));
    });
    db.collection("Project-Manager").onSnapshot((snapshot) => {
      setProjectList(snapshot.docs.map((doc) => doc.data()));
    });
  }, []);

  return (
    <div>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Invoice" />
            <Tab label="Store Details" />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <table>
              <thead>
                <th>
                  <TextField
                    variant="outlined"
                    label="Invoice#"
                    name="invoice"
                    value={values.invoice}
                    onChange={handleInputChange}
                  />
                </th>

                <th>
                  <InputLabel id="projectName">Project</InputLabel>
                  <Select
                    labelId="projectName"
                    variant="outlined"
                    label="Project"
                    name="projectName"
                    value={values.projectName}
                    onChange={handleInputChange}
                  >
                    {projectList
                      .sort(function (a, b) {
                        var nameA = a.project.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.project.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }

                        // names must be equal
                        return 0;
                      })
                      .map((item) => (
                        <MenuItem key={item.id} value={item.project}>
                          {item.project}
                        </MenuItem>
                      ))}
                  </Select>
                </th>
                <th>
                  <TextField
                    variant="outlined"
                    label="Project#"
                    name="projectNumber"
                    value={values.projectNumber}
                    onChange={handleInputChange}
                  />
                </th>
              </thead>
              <tbody>
                <tr>
                  <td
                  //style={{ borderWidth: 0 }}
                  >
                    <DatePicker
                      name="invoiceDate"
                      label="Invoice Date"
                      value={values.invoiceDate}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    {/* <DatePicker
                        name="installDate"
                        label="Install Date"
                        value={values.installDate}
                        onChange={handleInputChange}
                      /> */}
                    <div
                      style={{
                        width: "80%",
                        alignItems: "center",
                        marginLeft: "2vw",
                      }}
                    >
                      <label>Install Date</label>
                      <DateRangePickerComponent
                        placeholder="Install Date"
                        value={values.installDate}
                        label="Install Date"
                        name="installDate"
                        // onChange={handleInputChange}
                        onChange={(date) => {
                          if (date) {
                            setValues({
                              ...values,
                              installDate: date.value,
                              folderDate: date.value[0],
                            });
                          }
                        }}
                      ></DateRangePickerComponent>
                    </div>

                    {/* <button onClick={() => switchDatePicker()}>
                      {multiDays ? "Multiple Days" : "SingleDay"}
                    </button> */}
                  </td>
                  <td>
                    <TextField
                      variant="outlined"
                      label="Store#"
                      name="store"
                      value={values.store}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextField
                      variant="outlined"
                      label="Store Address"
                      name="address"
                      value={values.address}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <TextField
                      variant="outlined"
                      label="City"
                      name="city"
                      value={values.city}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <InputLabel id="province">Province</InputLabel>
                    <Select
                      labelId="province"
                      variant="outlined"
                      label="Province"
                      name="province"
                      value={values.province}
                      onChange={handleInputChange}
                    >
                      <MenuItem aria-label="None" value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"NL"}>
                        Newfoundland and Labrador
                      </MenuItem>
                      <MenuItem value={"PE"}>Prince Edward</MenuItem>
                      <MenuItem value={"NS"}>Nova Scotia</MenuItem>
                      <MenuItem value={"NB"}>New Brunswick</MenuItem>
                      <MenuItem value={"QC"}>Quebec</MenuItem>
                      <MenuItem value={"ON"}>Ontario</MenuItem>
                      <MenuItem value={"MB"}>Manitoba</MenuItem>
                      <MenuItem value={"SK"}>Saskatchewan</MenuItem>
                      <MenuItem value={"AB"}>Alberta</MenuItem>
                      <MenuItem value={"BC"}>British Columbia</MenuItem>
                      <MenuItem value={"YT"}>Yukon</MenuItem>
                      <MenuItem value={"NT"}>Northwest Territories</MenuItem>
                      <MenuItem value={"NU"}>Nunavut</MenuItem>
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextField
                      variant="outlined"
                      label="Hours Worked"
                      name="hours"
                      value={values.hours}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <TextField
                      variant="outlined"
                      label="Deliverables"
                      name="deliverables"
                      value={values.deliverables}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <TextField
                      variant="outlined"
                      label="Travel Time"
                      name="travelTime"
                      value={values.travelTime}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextField
                      variant="outlined"
                      label="Mileage"
                      name="mileage"
                      value={values.mileage}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <TextField
                      variant="outlined"
                      label="Food"
                      name="food"
                      value={values.food}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td rowSpan={2}>
                    <div
                      style={{
                        fontSize: 13,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <label for="luggage">Luggage</label>
                        <input
                          id="luggage"
                          name="luggage"
                          value={values.luggage}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div>
                        <label for="parking">Parking</label>
                        <input
                          id="parking"
                          name="parking"
                          value={values.parking}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div>
                        <label for="tools">Material</label>
                        <input
                          id="tools"
                          name="tools"
                          value={values.tools}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label for="gas">Gas</label>
                        <input
                          id="gas"
                          name="gas"
                          value={values.gas}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label for="ferry">Ferry</label>
                        <input
                          id="ferry"
                          name="ferry"
                          value={values.ferry}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <textarea
                        id="w3review"
                        name="notes"
                        rows="4"
                        cols="50"
                        placeholder="Notes For BFG"
                        value={values.notes}
                        onChange={handleInputChange}
                        style={{ maxWidth: "90%", minWidth: "50%" }}
                      ></textarea>
                    </div>
                  </td>
                  <td>
                    <div>
                      <textarea
                        id="w4review"
                        name="netechNotes"
                        rows="4"
                        cols="50"
                        placeholder="Notes For Netech"
                        value={values.netechNotes}
                        onChange={handleInputChange}
                        style={{
                          maxWidth: "90%",
                          minWidth: "50%",
                          fontSize: "40",
                        }}
                      ></textarea>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.button}
                      startIcon={<SaveIcon />}
                      onClick={() => saveInvoice()}
                    >
                      Submit
                    </Button>
                  </td>
                  <td colSpan={2}>
                    <div>
                      {error && (
                        <p>
                          <Alert severity="error">
                            <p>{error}</p>
                          </Alert>
                        </p>
                      )}
                      {success && (
                        <p>
                          <Alert severity="success">
                            <p>{success}</p>
                          </Alert>
                        </p>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <table>
              <tr>
                <td>
                  <InputLabel id="projectName">Project</InputLabel>
                  <Select
                    labelId="projectName"
                    variant="outlined"
                    label="Project"
                    name="projectName"
                    value={values.projectName}
                    onChange={handleDetailsInput}
                  >
                    {projectList.map((item) => (
                      <MenuItem key={item.id} value={item.project}>
                        {item.project}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
                <td>
                  <TextField
                    variant="outlined"
                    label="Project#"
                    name="projectNumber"
                    value={values.projectNumber}
                    onChange={handleDetailsInput}
                  />
                </td>
                <td>
                  {/* <DatePicker
                    name="installDate"
                    label="Install Date"
                    value={values.installDate}
                    onChange={handleDetailsInput}
                  /> */}
                  <div
                    style={{
                      width: "80%",
                      alignItems: "center",
                      marginLeft: "2vw",
                    }}
                  >
                    <label>Install Date</label>
                    <DateRangePickerComponent
                      placeholder="Install Date"
                      value={values.installDate}
                      label="Install Date"
                      name="installDate"
                      //onChange={handleInputChange}
                      onChange={(date) => {
                        if (date) {
                          setValues({
                            ...values,
                            installDate: date.value,
                            folderDate: date.value[0],
                          });
                        }
                      }}
                    ></DateRangePickerComponent>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    variant="outlined"
                    label="Store#"
                    name="store"
                    value={values.store}
                    onChange={handleDetailsInput}
                  />
                </td>
                <td>
                  <TextField
                    variant="outlined"
                    label="Store Address"
                    name="address"
                    value={values.address}
                    onChange={handleDetailsInput}
                  />
                </td>
                <td>
                  <TextField
                    variant="outlined"
                    label="City"
                    name="city"
                    value={values.city}
                    onChange={handleDetailsInput}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ borderWidth: 0 }}></td>
                <td>
                  <InputLabel id="province">Province</InputLabel>
                  <Select
                    labelId="province"
                    variant="outlined"
                    label="Province"
                    name="province"
                    value={values.province}
                    onChange={handleDetailsInput}
                  >
                    <MenuItem aria-label="None" value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"NL"}>Newfoundland and Labrador</MenuItem>
                    <MenuItem value={"PE"}>Prince Edward</MenuItem>
                    <MenuItem value={"NS"}>Nova Scotia</MenuItem>
                    <MenuItem value={"NB"}>New Brunswick</MenuItem>
                    <MenuItem value={"QC"}>Quebec</MenuItem>
                    <MenuItem value={"ON"}>Ontario</MenuItem>
                    <MenuItem value={"MB"}>Manitoba</MenuItem>
                    <MenuItem value={"SK"}>Saskatchewan</MenuItem>
                    <MenuItem value={"AB"}>Alberta</MenuItem>
                    <MenuItem value={"BC"}>British Columbia</MenuItem>
                    <MenuItem value={"YT"}>Yukon</MenuItem>
                    <MenuItem value={"NT"}>Northwest Territories</MenuItem>
                    <MenuItem value={"NU"}>Nunavut</MenuItem>
                  </Select>
                </td>
              </tr>
              <tr>
                <td>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    startIcon={<SaveIcon />}
                    onClick={handleAddDetails}
                  >
                    Save
                  </Button>
                </td>
                <td colSpan={2}>
                  {error && (
                    <p>
                      <Alert severity="error">
                        <p>{error}</p>
                      </Alert>
                    </p>
                  )}
                  {success && (
                    <p>
                      <Alert severity="success">
                        <p>{success}</p>
                      </Alert>
                    </p>
                  )}
                </td>
              </tr>
            </table>
          </TabPanel>
        </SwipeableViews>
      </div>
    </div>
  );
}

export default Home;
