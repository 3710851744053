import "./App.css";
import React from "react";
import Home from "./Home";
import Nav from "./Nav";
import InvoiceList from "./InvoiceList";
import { makeStyles, Paper } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//import Employees from "./Employees";
import Projects from "./Projects";
import Login from "./Login";
//import Edit from "./Edit";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: "white",
    height: "max",
  },
}));
function Dashboard() {
  const classes = useStyles();
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/">
            <Nav />
            <Paper className={classes.pageContent}>
              <Home />
            </Paper>
          </Route>
          <Route path="/2021">
            <Nav />
            <Paper className={classes.pageContent}>
              <InvoiceList listName="invoices" />
            </Paper>
          </Route>
          <Route path="/invoiceList">
            <Nav />
            <Paper className={classes.pageContent}>
              <InvoiceList listName="invoices2022" />
            </Paper>
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/projects">
            <Nav />
            <Paper className={classes.pageContent}>
              <Projects />
            </Paper>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default Dashboard;
