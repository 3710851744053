import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Login";
import { auth } from "./firebase";
import Dashboard from "./Dashboard";

function App() {
  const [person, setPerson] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setPerson(authUser);
      } else {
        setPerson(null);
      }
      //console.log(authUser);
    });
  }, []);
  return (
    <div className="app">
      {person ? (
        <Dashboard />
      ) : (
        <Router>
          <Switch>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </Router>
      )}
    </div>
  );
}

export default App;
