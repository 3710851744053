import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyD1nzc5BHNyGhksTjkM1uNnLNUgJnua-V8",
  authDomain: "billing-tracker-317014.firebaseapp.com",
  projectId: "billing-tracker-317014",
  storageBucket: "billing-tracker-317014.appspot.com",
  messagingSenderId: "1059137524094",
  appId: "1:1059137524094:web:4a743162d9aef7a1c2632f",
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();
export { db, auth };
