import React from "react";

function DisplayDetails({ data, installFrom, installTo }) {
  return (
    <div>
      <table
        className="detailsTable"
        style={{
          borderWidth: 0,
          marginBottom: 0,
          maxHeight: "100vh",
          minHeight: "80vh",
        }}
      >
        <tr>
          <td colSpan={3}>
            <p>
              <b>Invoice #</b> {data.invoice}
            </p>
            <p>
              <b>Invoice Date:</b> {data.invoiceDate}
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>
            <p>
              <b>Project:</b>
              {data.projectName}
            </p>

            <p>
              <b>Project Number</b> : {data.projectNumber}
            </p>
          </td>

          <td colSpan={2} style={{ textAlign: "left" }}>
            <p>
              <b>Store:</b> {data.store}
            </p>
            <p>
              <b>Install Date:</b>{" "}
              {installFrom === installTo
                ? installFrom
                : installFrom + "-" + installTo}
            </p>
            <p>
              <b>Store Address:</b> {data.address},{data.city}, {data.province}
            </p>
          </td>
        </tr>

        <tr>
          <td colSpan={2}>Labour for {data.projectName}</td>
          <td>{data.hours} hours</td>
        </tr>
        <tr>
          <td colSpan={2}>Deliverables</td>
          <td>{data.deliverables} hours</td>
        </tr>
        <tr>
          <td colSpan={2}>Travel</td>
          <td>{data.travelTime} hours</td>
        </tr>
        <tr>
          <td colSpan={2}>Mileage</td>
          <td>{data.mileage} km</td>
        </tr>
        <tr>
          <td colSpan={2}>Food</td>
          <td>$ {data.food} </td>
        </tr>
        <tr>
          <td colSpan={2}>Expense</td>
          <td>
            <p>Luggage: $ {data.luggage}</p>
            <p>Parking: $ {data.parking}</p>
            <p>Material: $ {data.tools}</p>
            <p> Gas: $ {data.gas}</p>
            <p>Ferry: ${data.ferry}</p>
          </td>
        </tr>
        <tr>
          <td>Notes For BFG</td>
          <td colSpan={2}>{data.notes}</td>
        </tr>
        <tr>
          <td>Notes For Netech</td>
          <td colSpan={2}>{data.netechNotes}</td>
        </tr>
      </table>
    </div>
  );
}

export default React.memo(DisplayDetails);
