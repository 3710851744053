//import { Button } from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import { db } from "./firebase";
import "./InvoiceList.css";

//import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";
import axios from "./axios";
import FilterInvoice from "./InvoiceListComponents/FilterInvoice";
import FolderView from "./InvoiceListComponents/FolderView";
import EditInvoice from "./InvoiceListComponents/EditInvoice";
import SearchCriteria from "./InvoiceListComponents/SearchCriteria";
const useStyles = makeStyles({
  root: {
    height: 216,
    flexGrow: 1,
    maxWidth: 400,
  },
});
function InvoiceList({ listName }) {
  const [invoiceList, setInvoiceList] = useState([]);
  const [data, setData] = useState({});
  const [excelData, setExcelData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [filter, setFilter] = useState("");
  const [open, setOpen] = useState(false);
  const [currId, setCurrId] = useState();
  const [projectList, setProjectList] = useState([]);
  const [projectManager, setProjectManager] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [prevInvoice, setprevInvoice] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState();
  const [installFrom, setInstallFrom] = useState();
  const [installTo, setInstallTo] = useState();
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    db.collection(listName)
      .orderBy("invoice", "asc")
      .onSnapshot((snapshot) => {
        // snapshot.docs.map((doc) => {
        //   db.collection(listName).doc(doc.id).delete()
        //   console.log("deleting", doc.id)
        // } )
        setInvoiceList(snapshot.docs.map((doc) => doc.data()));
      });
    db.collection("Project-Manager").onSnapshot((snapshot) => {
      setProjectList(snapshot.docs.map((doc) => doc.data()));
    });

    // addCopyData(); // use in develppment mode
    //convertData();
  }, []);
  useEffect(() => {
    //console.log(data.installDate[0]);
    axios.get("").then((response) => {
      setExcelData(response.data);
    });
  }, [data.invoice]);
  const addCopyData = () => {
    console.log(JSON.stringify(invoiceList))
    invoiceList.map((item) => {
      console.log("backup dataaaa");
      db.collection("backupData2022").doc(item.id).set(item);
    });
  };
  const convertData = () => {
    invoiceList.forEach((item) => {
      //console.log(item.installDate[0]);
      //date = new Date(item.installDate[0]);
      //console.log(item.installDate[0])
      console.log("converted");
      db.collection(listName)
        .doc(item.id)
        .set({
          ...item,
          folderDate: item.installDate[0].toDate().toLocaleString("default", {
            month: "long",
            day: "numeric",
            year: "numeric",
          }),
        });
    });
  };

  const displayContent = useCallback(
    (data) => {
      //console.log(data.folderDate);
      setInstallFrom(
        data.installDate[0].toDate().toLocaleString("default", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      );
      setInstallTo(
        data.installDate[1].toDate().toLocaleString("default", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      );
      setData(data);
      setprevInvoice(data.invoice);
      setSelectedIndex(data.id);
      setEdit(false);
    },
    [
      setInstallFrom,
      setInstallTo,
      setData,
      setprevInvoice,
      setSelectedIndex,
      setEdit,
    ]
  );

  const handleDelete = useCallback(
    (invoiceId) => {
      setCurrId(invoiceId);
      setOpen(true);
    },
    [setCurrId, setOpen]
  );

  const deleteInvoice = () => {
    //db.collection("invoice").doc(currId).delete();
    db.collection(listName).doc(currId).delete();
    //delete functionality of excel data, ONLY use while deploying
    if (data.invoice) {
      for (var i = 0; i < excelData.length; i++) {
        if (excelData[i]["Invoice#"] === data.invoice) {
          axios.delete(`/${i}`);
        }
      }
    }
    setData({});
    setInstallFrom(null);
    setInstallTo(null);
    setOpen(false);
  };
  const editChanges = () => {
    setSuccess("");
    setEdit(true);
  };

  const updateChanges = () => {
    setSuccess("");
    //db.collection("invoice").doc(data.invoice).update({ invoice: data });
    if (data.invoice.toString() != Number(prevInvoice)) {
      for (var i = 0; i < excelData.length; i++) {
        if (excelData[i]["Invoice#"] === prevInvoice) {
          axios.delete(`/${i}`);
        }
      }
    }
    let updateDate = data.installDate[0].toLocaleString("default", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    if (data.invoice === "" || data.invoice === 0 || data.invoice === "0") {
      setSuccess("Invoice Saved but not submitted");

      db.collection(listName)
        .doc(data.id)
        .set({
          ...data,
          folderDate: updateDate,
        });
    } else {
      db.collection(listName)
        .doc(data.id)
        .set({
          ...data,
          folderDate: updateDate,
        });
      var ratePerHour = Number(43);

      var expenseList = [];
      if (data.luggage > 0) {
        expenseList.push("Luggage");
      }
      if (data.parking > 0) {
        expenseList.push("Parking");
      }
      if (data.tools > 0) {
        expenseList.push("Material");
      }
      if (data.gas > 0) {
        expenseList.push("Gas");
      }
      if (data.ferry > 0) {
        expenseList.push("Ferry");
      }
      var gstPercent = 0;
      if (
        data.province === "MB" ||
        data.province === "BC" ||
        data.province === "AB" ||
        data.province === "SK" ||
        data.province === "YT" ||
        data.province === "NT" ||
        data.province === "NU"
      ) {
        gstPercent = 5;
      } else if (data.province === "ON") {
        gstPercent = 13;
      } else if (data.province === "QC") {
        gstPercent = 14.975;
      } else if (
        data.province === "NB" ||
        data.province === "PE" ||
        data.province === "NS" ||
        data.province === "NL"
      ) {
        gstPercent = 15;
      } else {
        gstPercent = 0;
      }
      if (data.hours === "") {
        data.hours = 0;
      }
      if (data.deliverables === "") {
        data.deliverables = 0;
      }
      if (data.travelTime === "") {
        data.travelTime = 0;
      }
      if (data.mileage === "") {
        data.mileage = 0;
      }
      if (data.food === "") {
        data.food = 0;
      }

      var subtotal = Number(
        parseFloat(data.hours) * ratePerHour +
          parseFloat(data.deliverables) * ratePerHour +
          parseFloat(data.travelTime) * ratePerHour +
          parseFloat(data.mileage) * 0.6 +
          parseFloat(data.food)
      );
      var hst = Number((subtotal * parseFloat(gstPercent)) / 100);
      if (data.luggage === "") {
        data.luggage = 0;
      }
      if (data.parking === "") {
        data.parking = 0;
      }
      if (data.tools === "") {
        data.tools = 0;
      }
      if (data.gas === "") {
        data.gas = 0;
      }
      if (data.ferry === "") {
        data.ferry = 0;
      }
      var expenseAmount = Number(
        parseFloat(data.luggage) +
          parseFloat(data.parking) +
          parseFloat(data.tools) +
          parseFloat(data.gas) +
          parseFloat(data.ferry)
      );
      var expenseName = expenseList.toString();
      var total = subtotal + hst + expenseAmount;
      var projectM = "";
      projectList.forEach((item) => {
        if (item.project === data.projectName) {
          projectM = item.manager;
        }
      });
      var installRange;
      if (installFrom === installTo) {
        installRange = installFrom;
      } else {
        installRange = installFrom + "-" + installTo;
      }
      var object = {
        "Project Manager": projectM,
        Project: data.projectName,
        "Project Number": data.projectNumber,
        "Store Number": data.store,
        "Store Location Address": data.address,
        City: data.city,
        PROVINCE: data.province,
        "Install Date": installRange,
        Vendor: "Netech",
        "Invoice#": data.invoice,
        "Invoice Date": data.invoiceDate,
        "Labor Hours": data.hours,
        "Labour Rate": ratePerHour,
        "Labour Amount": data.hours * ratePerHour,
        "Deliverable Hours": data.deliverables,
        "Deliverable Rate": ratePerHour,
        "Deliverable Amount": data.deliverables * ratePerHour,
        "Travel Time": data.travelTime,
        "Travel Rate": ratePerHour,
        "Travel Amount": data.travelTime * ratePerHour,
        Mileage: data.mileage,
        "Rate per kms (CAD)": 0.6,
        "Milage Amount": data.mileage * 0.6,
        Food: data.food,
        "Other Charges": expenseName,
        "Luggage/Parking/Tools Amount": expenseAmount,
        Subtotal: Number(
          parseFloat(data.hours) * ratePerHour +
            parseFloat(data.deliverables) * ratePerHour +
            parseFloat(data.travelTime) * ratePerHour +
            parseFloat(data.mileage) * 0.6 +
            parseFloat(data.food)
        ).toFixed(2),
        HST: hst.toFixed(2),
        "Total Amount": total.toFixed(2),
        "Date Submitted": "",
        Note: data.notes,
      };
      setSuccess("");
      var checkPresent = false;
      for (var i = 0; i < excelData.length; i++) {
        if (excelData[i]["Invoice#"] === data.invoice) {
          axios.patch(`/${i}`, object).then((response) => {
            // console.log(response.data);
            setError("");
            setSuccess("Invoice Updated");
          });
          checkPresent = true;
        }
      }
      if (checkPresent == false) {
        axios.post("", object).then((response) => {
          setError("");
          setSuccess("Invoice Successfully Added");
        });
      }
    }
    setEdit(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });

    projectList.forEach((item) => {
      if (item.project === data.projectName) {
        setProjectManager(item.manager);
      }
    });
  };
  const classes = useStyles();
  var projects = [
    ...new Set(invoiceList.map((item) => item.projectName)),
  ].sort();
  var dates = [
    ...new Set(
      invoiceList
        .sort((a, b) => a.installDate[0] - b.installDate[0])
        .map((item) =>
          item.installDate[0].toDate().toLocaleString("default", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })
        )
    ),
  ];

  const sortedInvoiceList = invoiceList.sort((a, b) => a.invoice - b.invoice);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="container">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning!!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Invoice?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={deleteInvoice} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <FilterInvoice
        invoiceList={sortedInvoiceList}
        parentDeleteCallback={handleDelete}
        parentDisplayCallback={displayContent}
        selectedIndex={selectedIndex}
      />
      <div className="folderView">
        <FolderView
          invoiceList={invoiceList}
          parentDisplayCallback={displayContent}
        />
        {/* <SearchCriteria projectList={projectList} /> */}
      </div>
      <div className="details">
        <EditInvoice
          edit={edit}
          data={data}
          handleInputChange={handleInputChange}
          projectList={projectList}
          installFrom={installFrom}
          installTo={installTo}
          setData={setData}
          setInstallFrom={setInstallFrom}
          setInstallTo={setInstallTo}
          editChanges={editChanges}
          updateChanges={updateChanges}
          setEdit={setEdit}
          success={success}
        />
      </div>
    </div>
  );
}

export default InvoiceList;
