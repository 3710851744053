import React from "react";

import { Alert } from "@material-ui/lab";
import DatePicker from "../components/DatePicker";
import {
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import DisplayDetails from "../DetailsComponent/DisplayDetails";
import { Link } from "react-router-dom";

function EditInvoice({
  edit,
  data,
  //handleInputChange,
  projectList,
  installFrom,
  installTo,
  setData,
  setInstallFrom,
  setInstallTo,
  editChanges,
  updateChanges,
  success,
  setEdit,
}) {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <div>
      {edit ? (
        <table>
          <thead>
            <th>
              <TextField
                variant="outlined"
                label="Invoice#"
                name="invoice"
                value={data.invoice}
                onChange={handleInputChange}
              />
            </th>

            <th>
              <InputLabel id="projectName">Project</InputLabel>
              <Select
                labelId="projectName"
                variant="outlined"
                label="Project"
                name="projectName"
                value={data.projectName}
                onChange={handleInputChange}
              >
                {projectList
                  .sort(function (a, b) {
                    var nameA = a.project.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.project.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }

                    // names must be equal
                    return 0;
                  })
                  .map((item) => (
                    <MenuItem key={item.id} value={item.project}>
                      {item.project}
                    </MenuItem>
                  ))}
              </Select>
            </th>
            <th>
              <TextField
                variant="outlined"
                label="Project#"
                name="projectNumber"
                value={data.projectNumber}
                onChange={handleInputChange}
              />
            </th>
          </thead>
          <tbody>
            <tr>
              <td>
                <DatePicker
                  name="invoiceDate"
                  label="Invoice Date"
                  value={data.invoiceDate}
                  onChange={handleInputChange}
                />
              </td>
              <td>
                <label>Install Date</label>
                <DateRangePickerComponent
                  placeholder="Install Date"
                  value={[new Date(installFrom), new Date(installTo)]}
                  label="Install Date"
                  name="installDate"
                  onChange={(date) => {
                    if (date) {
                      setData({
                        ...data,
                        installDate: date.value,
                        folderDate: date.value[0],
                      });

                      setInstallFrom(
                        date.value[0].toLocaleString("default", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })
                      );
                      setInstallTo(
                        date.value[1].toLocaleString("default", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })
                      );
                    }
                  }}
                ></DateRangePickerComponent>
              </td>
              <td>
                <TextField
                  variant="outlined"
                  label="Store#"
                  name="store"
                  value={data.store}
                  onChange={handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextField
                  variant="outlined"
                  label="Store Address"
                  name="address"
                  value={data.address}
                  onChange={handleInputChange}
                />
              </td>
              <td>
                <TextField
                  variant="outlined"
                  label="City"
                  name="city"
                  value={data.city}
                  onChange={handleInputChange}
                />
              </td>
              <td>
                <InputLabel id="province">Province</InputLabel>
                <Select
                  labelId="province"
                  variant="outlined"
                  label="Province"
                  name="province"
                  value={data.province}
                  onChange={handleInputChange}
                >
                  <MenuItem aria-label="None" value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"NL"}>Newfoundland and Labrador</MenuItem>
                  <MenuItem value={"PE"}>Prince Edward</MenuItem>
                  <MenuItem value={"NS"}>Nova Scotia</MenuItem>
                  <MenuItem value={"NB"}>New Brunswick</MenuItem>
                  <MenuItem value={"QC"}>Quebec</MenuItem>
                  <MenuItem value={"ON"}>Ontario</MenuItem>
                  <MenuItem value={"MB"}>Manitoba</MenuItem>
                  <MenuItem value={"SK"}>Saskatchewan</MenuItem>
                  <MenuItem value={"AB"}>Alberta</MenuItem>
                  <MenuItem value={"BC"}>British Columbia</MenuItem>
                  <MenuItem value={"YT"}>Yukon</MenuItem>
                  <MenuItem value={"NT"}>Northwest Territories</MenuItem>
                  <MenuItem value={"NU"}>Nunavut</MenuItem>
                </Select>
              </td>
            </tr>
            <tr>
              <td>
                <TextField
                  variant="outlined"
                  label="Hours Worked"
                  name="hours"
                  value={data.hours}
                  onChange={handleInputChange}
                />
              </td>
              <td>
                <TextField
                  variant="outlined"
                  label="Deliverables"
                  name="deliverables"
                  value={data.deliverables}
                  onChange={handleInputChange}
                />
              </td>
              <td>
                <TextField
                  variant="outlined"
                  label="Travel Time"
                  name="travelTime"
                  value={data.travelTime}
                  onChange={handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextField
                  variant="outlined"
                  label="Mileage"
                  name="mileage"
                  value={data.mileage}
                  onChange={handleInputChange}
                />
              </td>
              <td>
                <TextField
                  variant="outlined"
                  label="Food"
                  name="food"
                  value={data.food}
                  onChange={handleInputChange}
                />
              </td>
              <td>
                <div
                  style={{
                    fontSize: 13,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <label for="luggage">Luggage</label>
                    <input
                      id="luggage"
                      name="luggage"
                      value={data.luggage}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div>
                    <label for="parking">Parking</label>
                    <input
                      id="parking"
                      name="parking"
                      value={data.parking}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div>
                    <label for="tools">Material</label>
                    <input
                      id="tools"
                      name="tools"
                      value={data.tools}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label for="gas">Gas</label>
                    <input
                      id="gas"
                      name="gas"
                      value={data.gas}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label for="ferry">Ferry</label>
                    <input
                      id="ferry"
                      name="ferry"
                      value={data.ferry}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td> Notes For BFG</td>
              <td colSpan={2}>
                <div>
                  <textarea
                    name="notes"
                    rows="4"
                    cols="50"
                    placeholder="Note"
                    value={data.notes}
                    onChange={handleInputChange}
                    style={{ maxWidth: "90%", minWidth: "50%" }}
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td> Notes For Netech</td>
              <td colSpan={2}>
                <div>
                  <textarea
                    name="netechNotes"
                    rows="4"
                    cols="50"
                    placeholder="Netech Notes"
                    value={data.netechNotes}
                    onChange={handleInputChange}
                    style={{ maxWidth: "90%", minWidth: "50%" }}
                  ></textarea>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <DisplayDetails
          data={data}
          installFrom={installFrom}
          installTo={installTo}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => editChanges()}
          disabled={edit ? true : false}
        >
          Edit
        </Button>

        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => updateChanges()}
          disabled={edit ? false : true}
        >
          Save
        </Button>
        {edit && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setEdit(false)}
          >
            Cancel
          </Button>
        )}
      </div>

      {success && (
        <p>
          <Alert severity="success">
            <p>{success}</p>
          </Alert>
        </p>
      )}
    </div>
  );
}

export default React.memo(EditInvoice);
